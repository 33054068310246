<template>
    <v-container>
      <v-snackbar
      :color="color"
      top
      v-model="snackbar"
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="text-h5 font-weight-bold primary--text mb-5">Trip Information</h3>
          <v-text-field
          v-model="trip.name"
          class="rounded-lg"
          :rules="[v => !!v || 'Please enter title']"
          outlined
          prepend-inner-icon="mdi-airplane-takeoff"
          label="Trip Name"
          ></v-text-field>

          <v-textarea
          v-model="trip.description"
          class="rounded-lg"
          :rules="[v => !!v || 'Please enter description']"
          outlined
          label="Trip Description"
          ></v-textarea>

          <v-text-field
          v-model="citiesNum"
          class="rounded-lg"
          :rules="[v => !!v || 'Please enter cities number', v => v < 6 && v > 0 || 'Please choose from 1 to 5 cities']"
          :min="1"
          :max="5"
          type="number"
          outlined
          label="How many cities ?"
          hint="Not including origin"
          persistent-hint
          ></v-text-field>

          <v-text-field
          v-model="travellersNum"
          class="rounded-lg mt-5"
          :rules="[v => !!v || 'Please enter cities number', v => v < 11 && v >= 0 || 'Please choose from 0 to 10 travellers']"
          :min="0"
          :max="5"
          type="number"
          outlined
          label="How many confirmed travellers ?"
          ></v-text-field>

        </v-col>
        <v-col cols="12" md="8">
          <div style="border-left: 1px solid #9a9a9a; padding-left: 15px; min-height: 500px;">
            <h3 class="text-h5 font-weight-bold primary--text mb-5">Trip Cities</h3>
            <!-- start point -->
            <h4 class="primary--text mb-3">Start From</h4>
            <GmapAutocomplete
            class="cities-google"
            @place_changed="setOriginPlace"
            />

            <!-- inner cities -->
            <div v-for="n in Number(citiesNum)" :key="n">
              <h4 class="primary--text my-3">City {{n}}</h4>
              <v-form v-model="validForm" ref="plannerForm">
                <v-row>
                  <v-col cols="12" md="4">
                    <GmapAutocomplete
                    class="cities-google"
                    @change="cityIndex = n - 1"
                    @place_changed='setPlace'
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                    v-model="trip.stops[n - 1].fromDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="From Date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          :rules="[v => !!v || 'This field is required']"
                          v-bind="attrs"
                          :value="trip.stops[n-1].fromDate"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="trip.stops[n-1].fromDate"
                        @input="trip.stops[n - 1].fromDateMenu = false"
                        :min="minFromDate(n-1)"
                        :disabled="disableDate('from', n-1)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                    v-model="trip.stops[n - 1].toDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="To Date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          :rules="[v => !!v || 'This field is required']"
                          v-bind="attrs"
                          :value="trip.stops[n-1].toDate"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="trip.stops[n-1].toDate"
                        @input="trip.stops[n - 1].toDateMenu = false"
                        :min="minToDate(n-1)"
                        :disabled="disableDate('to', n-1)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
            </v-form>
            </div>

            <!-- end point -->
            <h4 class="primary--text my-3">End At</h4>
            <GmapAutocomplete
            style="border: 1px solid #9E9E9E; border-radius: 8px; height: 56px; width: 100%; padding: 5px;"
            @place_changed='setDestinationPlace'
            />
        </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn tile elevation="0" x-large color="primary" class="d-clock ma-2" @click="saveTripPlan('list')">SAVE ITINERARY</v-btn>
        <v-btn tile elevation="0" x-large color="primary" class="d-clock ma-2" @click="saveTripPlan('book')">SAVE ITINERARY and start booking</v-btn>
      </v-row>
    </v-container>
</template>

<script>
import planner from '@/web_services/planner'

export default {
  data () {
    return {
      validForm: true,
      snackbar: false,
      color: '',
      text: '',
      trip: {
        name: '',
        description: '',
        origin: {
          fromDate: '',
          toDate: '',
          place: null
        },
        destination: {
          fromDate: '',
          toDate: '',
          place: null
        },
        stops: [
          {
            fromDate: '',
            toDate: '',
            place: null,
            fromDateMenu: false,
            toDateMenu: false
          }
        ]
      },
      citiesNum: 1,
      travellersNum: 0,
      currentPlace: null,
      cityIndex: null,
      saveLoad: false
    }
  },
  watch: {
    citiesNum (newVal, oldVal) {
      if (oldVal > newVal) this.trip.stops.pop()
      else {
        this.trip.stops.push({
          fromDate: '',
          toDate: '',
          place: null,
          fromDateMenu: false,
          toDateMenu: false
        })
      }
    }
  },
  methods: {
    setPlace (place) {
      console.log(place)
      this.trip.stops[this.cityIndex].place = place
    },
    setOriginPlace (place) {
      console.log(place)
      this.trip.origin.place = place
    },
    setDestinationPlace (place) {
      console.log(place)
      this.trip.destination.place = place
    },
    minFromDate (index) {
      if (index === 0) return new Date().toISOString()
      else if (index > 0 && this.trip.stops[index - 1].toDate) {
        const lastDate = new Date(this.trip.stops[index - 1].toDate.replaceAll('-', '/'))
        return new Date(lastDate.setDate(lastDate.getDate() + 1)).toISOString()
      } else return new Date().toISOString()
    },
    minToDate (index) {
      if (this.trip.stops[index].fromDate) return this.trip.stops[index].fromDate
      else return new Date().toISOString()
    },
    disableDate (type, index) {
      if (type === 'from') {
        if (index === 0) return false
        else {
          return !this.trip.stops[index - 1].toDate
        }
      } else return !this.trip.stops[index].fromDate
      // return false
    },
    saveTripPlan (type) {
      if (this.validForm && this.trip.origin.place && this.trip.destination.place) {
        this.saveLoad = true
        const formData = new FormData()
        formData.append('name', this.trip.name)
        formData.append('description', this.trip.description)
        formData.append('number_of_travellers', this.travellersNum)
        this.trip.origin.fromDate = this.trip.stops[0].fromDate
        console.log(this.trip.stops)
        this.trip.destination.fromDate = this.trip.stops[this.trip.stops.length - 1].toDate

        formData.append('stops[0][stop_from_date]', this.trip.origin.fromDate)
        formData.append('stops[0][stop_to_date]', this.trip.origin.toDate)
        formData.append('stops[0][stop_To_lat]', this.trip.origin.place.geometry.location.lat())
        formData.append('stops[0][stop_To_lng]', this.trip.origin.place.geometry.location.lng())
        formData.append('stops[0][stop_name]', this.trip.origin.place.name)
        formData.append('stops[0][formatted_address]', this.trip.origin.place.formatted_address)
        formData.append('stops[0][stop_number]', 1)
        formData.append('stops[0][location_label]', this.trip.origin.place.name)

        this.trip.stops.forEach((element, i) => {
          console.log('count city', i + 1)
          formData.append(`stops[${i + 1}][stop_from_date]`, element.fromDate)
          formData.append(`stops[${i + 1}][stop_to_date]`, element.toDate)
          formData.append(`stops[${i + 1}][stop_To_lat]`, element.place.geometry.location.lat())
          formData.append(`stops[${i + 1}][stop_To_lng]`, element.place.geometry.location.lng())
          formData.append(`stops[${i + 1}][stop_name]`, element.place.name)
          formData.append(`stops[${i + 1}][formatted_address]`, element.place.formatted_address)
          formData.append(`stops[${i + 1}][stop_number]`, i + 2)
          formData.append(`stops[${i + 1}][location_label]`, element.place.name)
        })

        const stopsNum = this.trip.stops.length
        formData.append(`stops[${stopsNum + 1}][stop_from_date]`, this.trip.destination.fromDate)
        formData.append(`stops[${stopsNum + 1}][stop_to_date]`, this.trip.destination.toDate)
        formData.append(`stops[${stopsNum + 1}][stop_To_lat]`, this.trip.destination.place.geometry.location.lat())
        formData.append(`stops[${stopsNum + 1}][stop_To_lng]`, this.trip.destination.place.geometry.location.lng())
        formData.append(`stops[${stopsNum + 1}][stop_name]`, this.trip.destination.place.name)
        formData.append(`stops[${stopsNum + 1}][formatted_address]`, this.trip.destination.place.formatted_address)
        formData.append(`stops[${stopsNum + 1}][stop_number]`, stopsNum + 2)
        formData.append(`stops[${stopsNum + 1}][location_label]`, this.trip.origin.place.name)
        planner.addQuickPlan(formData).then(response => {
          if (type === 'list') this.$router.push('/plan-trip')
          else {
            this.$router.push({ name: 'plannerBook', params: { id: response.data.data.id } })
          }
        }).catch(err => {
          console.log(err.data)
        }).finally(() => {
          this.saveLoad = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  }
}
</script>

<style>
  .cities-google {
    border: 1px solid #9E9E9E;
    border-radius: 8px;
    height: 56px;
    width: 100%;
    padding: 5px;
    outline-color: #f94987 !important;
  }
</style>
